import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { GiHamburgerMenu } from "react-icons/gi";

const DiscoverIcon = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const backgroundColor = colorMode === "light" ? "white" : "black";

  return (
    <>
      <Container
        maxW={650}
        mt={0}
        style={{
          position: "sticky",
          top: 0,
          paddingTop: 6,
          backgroundColor: backgroundColor,
          zIndex: 99,
        }}
      >
        <Flex>
          <Box
            onClick={toggleColorMode}
            mb={0}
            p="4"
            ml={0}
            display="flex"
            alignItems="center"
            cursor="pointer"
          >
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </Box>
          <Spacer />
          <Box p="4" ml={0} display="flex" alignItems="center" height={70}>
            <Link href={`/`}>
              {colorMode === "dark" && (
                <Image
                  src="/SC Icon_B_White.svg"
                  width={25}
                  height={100}
                  alt="Discover more Songcards"
                />
              )}
              {colorMode === "light" && (
                <Image
                  src="/SC Icon_B_Black.svg"
                  width={25}
                  height={100}
                  alt="Discover more Songcards"
                />
              )}
            </Link>
          </Box>
          <Spacer />
          <Menu>
            <Box mt={4}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<GiHamburgerMenu size={20} />}
                variant="outline"
                borderWidth={0}
                _focus={{ outline: "none", boxShadow: "none" }}
                _active={{ bg: "transparent" }}
                _hover={{ bg: "transparent" }}
              />
            </Box>
            <MenuList
              backgroundColor={colorMode === "light" ? "white" : "black"}
            >
              <Link href={"/info"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">What is a Songcard?</Text>
                </MenuItem>
              </Link>
              <Link href={"/artist-guide"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">For Artists</Text>
                </MenuItem>
              </Link>
              <Link href={"/blog/christopher-carvalho"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">About</Text>
                </MenuItem>
              </Link>
              <Link href={"/search"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">Search</Text>
                </MenuItem>
              </Link>
              <Link href={"/terms"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">Terms</Text>
                </MenuItem>
              </Link>
              <Link href={"/get-app"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">App</Text>
                </MenuItem>
              </Link>
              {/* <Link href={"https://docs.google.com/forms/d/e/1FAIpQLSfelEQmiE8Yr589pqjhEDeGJXyqGwuYZ-ROTlTy_b6mw5ErqA/viewform?usp=sf_link"} target="_blank">
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">Support</Text>
                </MenuItem>
              </Link> */}
              {/* <Link href={"mailto:chris@songcards.io"} target="_blank">
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">Contact</Text>
                </MenuItem>
              </Link> */}
              <Link href={"/links"}>
                <MenuItem
                  backgroundColor={colorMode === "light" ? "white" : "black"}
                >
                  <Text fontSize="sm">more...</Text>
                </MenuItem>
              </Link>
            </MenuList>
          </Menu>
        </Flex>
        <Divider mt={2} mb={10} />
      </Container>
    </>
  );
};

export default DiscoverIcon;
