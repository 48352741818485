import DiscoverIcon from "@/components/DiscoverIcon";
import Loading from "@/components/Loading";
import PlayerBar from "@/player/PlayerBar";
import store from "@/store";
import "@/styles/globals.css";
import { theme } from "@/theme";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { Center, ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FaChevronUp } from "react-icons/fa";
import { Provider } from "react-redux";
import ScrollToTop from "react-scroll-to-top";
import { AudioPlayerProvider } from "react-use-audio-player";
// import '@fontsource/raleway/400.css'
// import '@fontsource/open-sans/700.css'

export default function App({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const handleStart = () => {
    window.scrollTo(0, 0);
    setLoading(true);
  };

  const handleComplete = () => {
    setLoading(false);
  };

  useEffect(() => {
    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  return (
    <>
      <ChakraProvider theme={theme}>
        <Provider store={store}>
          <AudioPlayerProvider>
            <UserProvider>
              <DiscoverIcon />
              {loading ? (
                <>
                  <Loading />
                </>
              ) : (
                <Component {...pageProps} />
              )}
              <PlayerBar />
              <ScrollToTop
                style={{
                  marginBottom: 60,
                  background: "linear-gradient(to right, rgba(255,153,213,0.9), rgba(255,102,196,0.9))",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 100,
                }}
                smooth
                component={
                  <Center>
                    <FaChevronUp size={30} color="white" />
                  </Center>
                }
              />
            </UserProvider>
          </AudioPlayerProvider>
        </Provider>
      </ChakraProvider>
    </>
  );
}
