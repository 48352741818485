import {
  Center,
  Container,
  Grid,
  Square,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiOutlinePause } from "react-icons/ai";
import { BiPlay } from "react-icons/bi";
import { FaPlay } from "react-icons/fa";
import { IoIosPause, IoMdPlay } from "react-icons/io";
import { useAudioPlayer } from "react-use-audio-player";

interface AudioPlayerProps {
  file: string;
  uuid: string;
  // image: string;
  title: string;
}

const AudioPlayer = ({ file }: AudioPlayerProps) => {
  const { togglePlayPause, ready, loading, playing } = useAudioPlayer({
    src: file,
    format: "mp3",
    preload: "none",
    autoplay: true,
    html5: true,
    onend: () => console.log("sound has ended!"),
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const { colorMode } = useColorMode();

  useEffect(() => {
    setIsPlaying(playing);
  }, [playing]);

  if (!file)
    return
    // return (
    //   <div style={{ minWidth: 100, marginTop: 5, marginBottom: 5 }}>
    //     ...
    //   </div>
    // );
  if (!ready && !loading) return <div>Audio not ready</div>;
  if (loading)
    return (
      <div>
        <Container maxW={900} maxH={40} mt={5} mb={5}>
          <Grid templateColumns="repeat(3, 1fr)" gap={6} alignItems="center">
            <Center></Center>
            <Center>
              <Text mt={1} mb={1} textAlign="center">
                Loading...
              </Text>
            </Center>
            <Center></Center>
          </Grid>
        </Container>
      </div>
    );

  return (
    <div>
      <Container maxW={900} maxH={40}>
        <Grid templateColumns="repeat(3, 1fr)" gap={6} alignItems="center">
          <Center></Center>
          <Center>
            <button onClick={togglePlayPause}>
              <Square
                maxW={70}
                padding={5}
                marginTop={2}
                marginBottom={2}
                
              >
                {isPlaying ? (
                  <IoIosPause
                    size={28}
                    color={colorMode === "dark" ? "white" : "black"}
                  />
                ) : (
                  <IoMdPlay
                    size={28}
                    color={colorMode === "dark" ? "white" : "black"}
                  />
                )}
              </Square>
            </button>
          </Center>
          <Center></Center>
        </Grid>
      </Container>
    </div>
  );
};

export default AudioPlayer;
