import { extendTheme } from "@chakra-ui/react";
// import '@fontsource-variable/open-sans'
// import '@fontsource-variable/raleway'
import "@fontsource-variable/inter-tight";
import "@fontsource/open-sans";
import "@fontsource/raleway";

const letterSpacing = 0.5

export const theme = extendTheme({
  fonts: {
    heading: `'Inter Tight Variable', sans-serif`,
    body: `'Inter Tight Variable', sans-serif`,
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
    extraBold: 800,
  },
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  dark: {
    900: "black",
    100: "#151515",
    300: "121212",
  },
  light: {
    100: "white",
  },
  styles: {
    global: (props: { colorMode: string }) => ({
      "html, body": {
        color: "white.600",
        bg: props.colorMode === "dark" ? "black" : "#ffffff",
        minHeight: "100vh",
        letterSpacing: letterSpacing, // Applies to body text
      },
    }),
  },
  components: {
    Heading: {
      baseStyle: {
        letterSpacing: letterSpacing, // Ensures headings also use this spacing
      },
    },
    Text: {
      baseStyle: {
        letterSpacing: letterSpacing, // Ensures headings also use this spacing
      },
    },
  },
  
});

