import { Button, Center, Spinner } from "@chakra-ui/react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import Link from "next/link";
import { useEffect, useState } from "react";

const Checkout = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const price = props.price;
  const card_id = props.card_id;
  const dollar_price = price * 100;
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [notSupported, setNotSupported] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  useEffect(() => {
    setLoadingCheckout(true);
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Purchase",
          amount: dollar_price,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        } else {
          setNotSupported(true);
        }
        setLoadingCheckout(false);
      });
    }
  }, [stripe]);

  const handlePaymentSuccess = (paymentMethod) => {
    setPaymentSuccess(true);

    const email_address = paymentMethod.billing_details.email;

    const message = "";
    const data = {
      to: "chris@unlockyoursound.com",
      subject: "💜 Your Songcard is here!",
      message: message,
      card_id: card_id,
    };

    axios
      .post("https://api.songcards.io/ninja/purchase", {
        email_address: email_address,
        card_id: card_id,
        subject: "💜 Your Songcard is here!",
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        alert(error);
        console.log(error);
      });
  };

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async (ev) => {
      const response = await axios.post(
        "https://api.songcards.io/ninja/create-payment-intent",
        {
          amount: dollar_price,
          automatic_payment_methods: {
            enabled: true,
          },
          currency: "usd",
        }
      );
      const clientSecret = response.data.client_secret;

      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        ev.complete("fail");
      } else {
        ev.complete("success");

        if (paymentIntent.status === "requires_action") {
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            console.log(error);
          } else {
            handlePaymentSuccess(ev.paymentMethod);
          }
        } else if (paymentIntent.status === "succeeded") {
          handlePaymentSuccess(ev.paymentMethod);
        }
      }
    });
    return (
      <>
        {paymentSuccess ? (
          <>
            <h1>
              <b>Success! 💜</b> Your Songcard will be delivered to your inbox
              shortly!
            </h1>
            <br></br>
          </>
        ) : null}
        {loadingCheckout && (
          <Center>
            <Spinner />
          </Center>
        )}
        {!loadingCheckout && (
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        )}
      </>
    );
  }

  return (
    <div>
      {notSupported && (
        <>
          <Center>
            <p>Checkout is not supported on this browser</p>
          </Center>
          <Center mt={5}>
            <Link href="/get-app">
              <Button variant="outline">Get App</Button>
            </Link>
          </Center>
        </>
      )}
    </div>
  );
};

export default Checkout;
