import { FiShare } from "react-icons/fi";
import { WhatsappShareButton } from "react-share";
import { IoMdShare } from "react-icons/io";

interface ShareProps {
  uuid: string;
}

function ShareButton({ uuid }: ShareProps) {
  const domainUrl = "https://songcards.io";
  const shareUrl = `${domainUrl}/cards/${uuid}`;
  const message = "Saw this and thought of you :-)";

  return (
    <WhatsappShareButton url={shareUrl} title={message}>
      <IoMdShare size={22}/>
    </WhatsappShareButton>
  );
}

export default ShareButton;
