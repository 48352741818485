import ShareButton from "@/components/ShareButton";
import Thumbnail from "@/components/Thumbnails";
import GiftCheckOutWrapper from "@/components/stripe/GiftCheckoutWrapper";
import { useAppSelector } from "@/hooks";
import {
  Center,
  Container,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { FaRegHeart } from "react-icons/fa";
import AudioPlayer from "./AudioPlayer";

function PlayerBar() {
  const { colorMode } = useColorMode();
  const card = useAppSelector((state) => state.nowplaying.card);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const backgroundColor = colorMode === "light" ? "white" : "black";

  return (
    <div
      // className="footer"
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: colorMode === "dark" ? "black" : "white",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        borderTopStyle: "dotted",
        borderTopWidth: "1px",
        borderTopColor: colorMode === "dark" ? "white" : "black",
      }}
    >
      <Container maxW={900} maxH={40}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          alignItems="center"
          justifyContent="center"
          ml={10}
          mr={10}
        >
          <Center>
            {card.title && <FaRegHeart onClick={onOpen} size={20} />}
          </Center>
          <AudioPlayer
            file={card.song.ext_file ?? card.song.file}
            uuid={card.uuid}
            title={card.title}
          />
          <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
            <ModalOverlay
              backdropInvert="80%"
              backdropFilter="blur(10px) hue-rotate(10deg)"
            />

            <ModalContent backgroundColor={backgroundColor} maxW={300} borderRadius={20}>
              <Text ml={6} mt={4}>
                You are listening to...
              </Text>
              <ModalHeader>{card.title}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Thumbnail
                  ext_file={card.ext_file}
                  comp_file={card.comp_file}
                />
                <Text fontSize="2xl" mt={5} mb={4}>
                  $2.99
                </Text>

                <GiftCheckOutWrapper
                  price={2.99}
                  art={""}
                  uuid={card.uuid}
                  image={card.comp_file ?? card.ext_file ?? ""}
                  id={card.id}
                  issued={""}
                  ext_file={card.ext_file ?? ""}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
          <Center>{card.title && <ShareButton uuid={card.uuid} />}</Center>
        </Grid>
      </Container>
    </div>
  );
}

export default PlayerBar;
